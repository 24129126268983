import { ReactElement, ReactMarkdown } from "react-markdown/lib/react-markdown";

import { TextTestimonial } from "data/homepage";

import { ImageWithBlur } from "~/components/common/ImageWithBlur/ImageWithBlur";

import * as styles from "./ReviewCard.css";

const ReviewCard = ({ review }: { review: TextTestimonial }): ReactElement => {
  return (
    <div className={styles.testimonialCardContainer}>
      <div className={styles.textTestimonialTopBar}>
        <div className={styles.textTestimonialImage}>
          <ImageWithBlur
            src={review.imageUrl}
            height={42}
            width={42}
            layout={"responsive"}
            objectFit={"cover"}
            alt={"Review writer's profile picture"}
          />
        </div>
        <div className={styles.textTestimonialNameContainer}>
          <span className={styles.textTestimonialName}>{review.name}</span>
          <span className={styles.textTestimonialUsername}>{review.username}</span>
        </div>
      </div>
      <ReactMarkdown className={styles.textTestimonialText}>{review.markdownText}</ReactMarkdown>
    </div>
  );
};

export default ReviewCard;
