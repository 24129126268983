export const CollapseIcon = () => (
  <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4999 1.91732H1.49992C0.995752 1.91732 0.583252 1.50482 0.583252 1.00065C0.583252 0.496485 0.995752 0.0839844 1.49992 0.0839844H12.4999C13.0041 0.0839844 13.4166 0.496485 13.4166 1.00065C13.4166 1.50482 13.0041 1.91732 12.4999 1.91732Z"
      fill="#545454"
    />
  </svg>
);

export const ExpandIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0.666016C8.50626 0.666016 8.91666 1.07642 8.91666 1.58268V7.08268H14.4167C14.9229 7.08268 15.3333 7.49309 15.3333 7.99935C15.3333 8.50561 14.9229 8.91602 14.4167 8.91602H8.91666V14.416C8.91666 14.9223 8.50626 15.3327 8 15.3327C7.49374 15.3327 7.08333 14.9223 7.08333 14.416V8.91602H1.58333C1.07707 8.91602 0.666664 8.50561 0.666664 7.99935C0.666664 7.49309 1.07707 7.08268 1.58333 7.08268H7.08333V1.58268C7.08333 1.07642 7.49374 0.666016 8 0.666016Z"
      fill="#545454"
    />
  </svg>
);

export const ArrowDown = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.8751 0.999517L5.9951 4.87952L2.1151 0.999517C1.92827 0.812264 1.67462 0.707031 1.4101 0.707031C1.14558 0.707031 0.891929 0.812264 0.705098 0.999517C0.315098 1.38952 0.315098 2.01952 0.705098 2.40952L5.2951 6.99952C5.6851 7.38952 6.3151 7.38952 6.7051 6.99952L11.2951 2.40952C11.6851 2.01952 11.6851 1.38952 11.2951 0.999517C10.9051 0.619517 10.2651 0.609517 9.8751 0.999517Z"
      fill="#545454"
    />
  </svg>
);

export const ArrowUp = () => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.357677 7.52778C0.825803 7.99757 1.58745 8.00308 2.06232 7.5401L6.78665 2.93403L11.4439 7.6079C11.912 8.0777 12.6737 8.0832 13.1486 7.62022C13.6234 7.15724 13.6289 6.40385 13.1607 5.93405L7.65125 0.404905C7.18313 -0.0648916 6.42148 -0.0703966 5.94661 0.392584L0.357775 5.84151C-0.104918 6.29262 -0.110449 7.05798 0.357677 7.52778Z" fill="#545454" />
  </svg>
);

export const ChevronArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15.8751 9.00001L11.9951 12.88L8.1151 9.00001C7.92827 8.81275 7.67462 8.70752 7.4101 8.70752C7.14558 8.70752 6.89193 8.81275 6.7051 9.00001C6.3151 9.39001 6.3151 10.02 6.7051 10.41L11.2951 15C11.6851 15.39 12.3151 15.39 12.7051 15L17.2951 10.41C17.6851 10.02 17.6851 9.39001 17.2951 9.00001C16.9051 8.62001 16.2651 8.61001 15.8751 9.00001Z" fill="#AFAFAF" />
  </svg>
);

export const CloseIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.77816 0.229417C9.63646 0.0873971 9.44408 0.00758438 9.24346 0.00758438C9.04284 0.00758438 8.85046 0.0873971 8.70876 0.229417L5 3.9306L1.29124 0.221832C1.14954 0.0798127 0.957162 0 0.756541 0C0.555921 0 0.363543 0.0798127 0.221843 0.221832C-0.0739477 0.517623 -0.0739477 0.99544 0.221843 1.29123L3.9306 4.99999L0.221843 8.70876C-0.0739477 9.00455 -0.0739477 9.48237 0.221843 9.77816C0.517634 10.0739 0.995449 10.0739 1.29124 9.77816L5 6.06939L8.70876 9.77816C9.00455 10.0739 9.48237 10.0739 9.77816 9.77816C10.0739 9.48237 10.0739 9.00455 9.77816 8.70876L6.0694 4.99999L9.77816 1.29123C10.0664 1.00302 10.0664 0.517623 9.77816 0.229417Z"
      fill="white"
    />
  </svg>
);

export const FilterIcon = () => (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
      fill="#545454"
    />
  </svg>
);
