import { ReactElement, useState, useEffect } from "react";

import classNames from "classnames";
import Link from "next/link";
import posthog from "posthog-js";

import { HeroData, YellowBannerObject } from "data/homepage";
import { CloseIcon } from "studio-design-system";

import * as styles from "./TopBannerCTA.css";

export const TopBannerCTA = ({ heroData, shouldOverrideWithCustomHeroData }: { heroData: HeroData, shouldOverrideWithCustomHeroData?: boolean }): ReactElement => {
  const [closeYellowBadge, setCloseYellowBadge] = useState(false);
  const [yellowBannerBoldTitle, setYellowBannerBoldTitle] = useState(heroData.titleB);
  const [yellowBannerDescription, setYellowBannerDescription] = useState(heroData.descriptionB);
  const [yellowBannerDescriptionAfterCountdown, setYellowBannerDescriptionAfterCountdown] =
    useState(heroData.descriptionAfterCountdown);
  const [yellowBannerCountdownEndDate, setYellowBannerCountdownEndDate] = useState(
    heroData.countdownEndDate,
  );
  const [yellowBannerLinkUrl, setYellowBannerLinkUrl] = useState(heroData.yellowBannerLinkUrl);
  const [yellowBannerTheme, setYellowBannerTheme] = useState(heroData.specialTheme || "none");
  const [shouldYellowBannerShow, setShouldYellowBannerShow] = useState(false);
  const currentTime = new Date().toISOString();

  useEffect(() => {

    // if (shouldOverrideWithCustomHeroData) {
    //   return; // don't do the regular based on the feature flag.
    // }

    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled("homepage-yellow-banner-text")) {
        const yellowBannerInfo = posthog.getFeatureFlagPayload(
          "homepage-yellow-banner-text",
        ) as unknown as YellowBannerObject;

        if (yellowBannerInfo) {
          console.log("TOP BANNER INFO: ", yellowBannerInfo, heroData);
          if (yellowBannerInfo.specialOfferTitle) {
            // For now don't show in music school landing pages anymore.
            setShouldYellowBannerShow(true);
          } else {
            //setShouldYellowBannerShow(true);
          }
          setYellowBannerBoldTitle(yellowBannerInfo.titleBoldText);
          setYellowBannerDescription(yellowBannerInfo.descriptionText);
          setYellowBannerDescriptionAfterCountdown(yellowBannerInfo.descriptionTextAfterCountdown);
          setYellowBannerCountdownEndDate(yellowBannerInfo.countdownEndDate);
          setYellowBannerLinkUrl(yellowBannerInfo.url);
          setYellowBannerTheme(yellowBannerInfo.specialTheme);
        }
      }
    });
  }, []);

  const bannerElement = (
    <div
      className={classNames(
        styles.yellowContainer,
        yellowBannerTheme === "holiday" && styles.holidayTheme,
        yellowBannerTheme === "spring" && styles.springTheme,
        yellowBannerTheme === "sfschool" && styles.sfSchoolTheme,
      )}
    >
      <div
        className={styles.yellowClose}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCloseYellowBadge(true);
        }}
      >
        <CloseIcon height={10} fill={yellowBannerTheme === "sfschool" || yellowBannerTheme === "spring" ? "#ffff" : "#000"} />
      </div>
      <span className={styles.yellowTitle}>{yellowBannerBoldTitle}</span>
      <span className={styles.yellowDash}> — </span>
      <span className={styles.yellowDesc}>
        {currentTime >= yellowBannerCountdownEndDate
          ? yellowBannerDescriptionAfterCountdown
          : yellowBannerDescription}
      </span>
    </div>
  );
  return (
    <>
      {!closeYellowBadge && shouldYellowBannerShow ? (
        heroData.specialCTA ? (
          <div onClick={heroData.specialCTA} className={styles.clickable}>
            {bannerElement}
          </div>
        ) : (
          <Link href={yellowBannerLinkUrl} passHref>
            <a className={styles.yellowLink}>
              {bannerElement}
            </a>
          </Link>
        )
      ) : null}
    </>
  );
};
