import React, { ReactElement } from "react";

import { useWindowSize } from "usehooks-ts";

import { LandingPageSources } from "~/components/homepage/useLandingPage";
import { useIsScrolledToActiveZone } from "~/hooks/useIsScrolledToActiveZone";

import { MarketingHeaderWithAuth } from "../MarketingHeaderWithAuth";
import { Experience } from "~/api/cmsApi";

interface Props {
  onSignInEvent?: () => void;
  hideBottomBorderInitially?: boolean;
  source: LandingPageSources;
  experience?: Experience;
}
export const LandingNavbarWithCTA = ({
  onSignInEvent,
  hideBottomBorderInitially,
}: Props): ReactElement => {
  const { width } = useWindowSize();
  const isScrolledForCTA = useIsScrolledToActiveZone(800);
  const isScrolledForBottomBorder = useIsScrolledToActiveZone(125);

  const isSmallScreen = width <= 600;
  const shouldReplaceLoginButtonWithCta = !isSmallScreen && isScrolledForCTA;
  const shouldHideBottomBorder = hideBottomBorderInitially && !isScrolledForBottomBorder;

  return (
    <MarketingHeaderWithAuth
      onSignInEvent={onSignInEvent}
      hideLoginButton={shouldReplaceLoginButtonWithCta}
      hideBottomBorder={shouldHideBottomBorder}
    />
  );
};
