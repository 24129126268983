import React, { useEffect, useMemo, useState } from "react";

import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";

import { classesCategories } from "data/classesTags/classesTags";
import { CollectionData } from "data/collections";
import { FooterData } from "data/footer";
import { JoinStudioData } from "data/footer/joinStudio";
import { HomepageData } from "data/homepage";
import { StorefrontData } from "data/storefront";
import { Button } from "studio-design-system";
import { useWindowSize } from "usehooks-ts";

import { HOME_PAGE_LOGIN, HOME_PAGE_VISIT } from "~/analytics/Amplitude/amplitudeConstants";
import { addUTMPropertiesToEvent } from "~/analytics/Amplitude/amplitudeUtils";
import { useAnalytics } from "~/analytics/useAnalytics";
import { Experience } from "~/api/cmsApi";
import { useAuth } from "~/hooks/auth/useAuth";

import Browse from "../browse/Browse";
import { BrowseSSGData } from "../browse/types";
import { ArrowRightLineIcon } from "../common/Icons/ArrowRightLineIcon";
import { LandingNavbarWithCTA } from "../common/LandingNavbarWithCTA/LandingNavbarWithCTA";
import { MarketingFooter } from "../common/MarketingFooter";
import { HeroSection } from "./HeroSection";
import ReviewCard from "./HeroSection/ReviewCard/ReviewCard";
import * as styles from "./Homepage.css";
import HomepageHead from "./HomepageHead";
import { LandingPageSources } from "./useLandingPage";

const CollectionHeader = dynamic(() => import("../collections/CollectionHeader/CollectionHeader"));
const CollectionAvailableBlock = dynamic(
  () => import("../collections/CollectionAvailableBlock/CollectionAvailableBlock"),
);

interface Props {
  homepageData: HomepageData;
  joinStudioData: JoinStudioData;
  footerData: FooterData;
  collectionData?: CollectionData;
  storefronts?: StorefrontData[];
  enrollingExperiences?: Experience[];
  browseData?: BrowseSSGData;
}

const HomepageComponent = ({
  homepageData,
  footerData,
  collectionData,
  browseData,
}: {
  homepageData: HomepageData;
  footerData: FooterData;
  collectionData?: CollectionData;
  browseData?: BrowseSSGData;
}): React.ReactElement => {
  const { width } = useWindowSize();

  const testimonialCount = useMemo(() => {
    return width < 600 ? 6 : 9;
  }, [width]);

  const smallerThan600 = useMemo(() => {
    return width < 600;
  }, [width]);

  // Dummy Loader Effect
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (loadMore) {
      setTimeout(() => {
        setLoadMore(false);
      }, 1000);
    }
  }, [loadMore]);

  return (
    <>
      <HeroSection
        heroData={homepageData.heroData}
        browseCategories={[...classesCategories].splice(0, 6)}
      />
      <Browse isHomepage browseData={browseData as BrowseSSGData} homepageData={homepageData} />
      {collectionData && (
        <div className={styles.collectionWrap}>
          <div className={styles.collectionInnerWrap}>
            <CollectionHeader collectionData={collectionData} showDescription={false} />
            <div className={styles.ctaContainer}>
              <Link href={`/collections/${collectionData.slug}`} passHref>
                <Button
                  style={{ display: "flex", gap: 10, alignItems: "center" }}
                  variant="secondary"
                >
                  <>
                    Explore Collection <ArrowRightLineIcon />{" "}
                  </>
                </Button>
              </Link>
            </div>
          </div>
          <div className={styles.blockWrap}>
            <CollectionAvailableBlock
              contentStyle={styles.textWhite}
              mobileView={smallerThan600}
              availableUntil={collectionData.availableUntil}
            />
          </div>
        </div>
      )}
      <div className={styles.reviewContainer}>
        <div>
          <h2 className={styles.reviewHeader}>
            <>Join {smallerThan600 ? "1M+" : "over 1,000,000 people"} learning on Studio</>
          </h2>
        </div>
        <div className={styles.reviewRow}>
          {homepageData.textTestimonials.slice(0, testimonialCount).map((review, key) => (
            <ReviewCard key={`review_${key}`} review={review} />
          ))}
        </div>
      </div>
      <MarketingFooter data={footerData} />
    </>
  );
};

export const Homepage = ({
  homepageData,
  footerData,
  collectionData,
  browseData,
}: Props): React.ReactElement => {
  const { trackAmplitudeEvent } = useAnalytics();
  const { user, loadingCurrentUser } = useAuth();
  useEffect(() => {
    trackAmplitudeEvent(HOME_PAGE_VISIT, addUTMPropertiesToEvent());
    const onBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const router = useRouter();

  useEffect(() => {
    if (!loadingCurrentUser && user) {
      // school
      if (user.currentSessionSlug) {
        // router.push(`school/music`);
      }
    }
  }, [loadingCurrentUser, user]);
  return (
    <div className={styles.container}>
      <HomepageHead homepageData={homepageData} />
      <LandingNavbarWithCTA
        onSignInEvent={() => {
          trackAmplitudeEvent(HOME_PAGE_LOGIN, addUTMPropertiesToEvent());
        }}
        source={LandingPageSources.Home}
      />
      <HomepageComponent {...{ homepageData, footerData, collectionData, browseData }} />
    </div>
  );
};
