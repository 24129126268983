import { withSSRContext } from "aws-amplify";
//import type { NextPage, GetServerSideProps, InferGetServerSidePropsType } from "next";
import type { NextPage, GetStaticProps, InferGetStaticPropsType } from "next";

import { CollectionData } from "data/collections";
import { getFooterData, FooterData } from "data/footer";
import { getJoinStudioData, JoinStudioData } from "data/footer/joinStudio";
import { ProductData } from "data/product/products";

import { getProductCollectionWithSlug } from "~/api/collectionsApi";
import { getMostPopularProducts, getNewReleasesProducts } from "~/api/productApi";
import { BrowseSSGData } from "~/components/browse/types";

import { HomepageData, getHomepageData } from "../../data/homepage";
import { Homepage } from "../components/homepage/Homepage";

interface StaticProps {
  homepageData: HomepageData;
  joinStudioData: JoinStudioData;
  footerData: FooterData;
  collectionData: CollectionData;
  browseData: BrowseSSGData;
}

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  homepageData,
  joinStudioData,
  footerData,
  collectionData,
  browseData,
}) => {
  return (
    <Homepage
      homepageData={homepageData}
      joinStudioData={joinStudioData}
      browseData={browseData}
      footerData={footerData}
      collectionData={collectionData}
    />
  );
};

export const getStaticProps: GetStaticProps<StaticProps> = async () => {
  // TODO: we should use Next middleware for this redirect check, so it can run on edge nodes
  // for much better performance.
  // see:
  // https://nextjs.org/docs/advanced-features/middleware
  // netlify support - https://www.netlify.com/blog/next.js-middleware-on-netlify/
  // Amplify issues out of the box - https://github.com/aws-amplify/amplify-js/issues/9145 (Cognito is trash - no surprise there)
  // but likely fixable with a custom check of JWT: https://github.com/aws-amplify/amplify-js/issues/9145#issuecomment-964338047
  //const { Auth } = withSSRContext(context);
  const collectionSlug = "all-music";
  const newReleases = await getNewReleasesProducts(true, 6);
  const popularProducts = await getMostPopularProducts(true, 24);
  const popularProductsProducts: ProductData[] = popularProducts.products;
  const collectionDataObj: CollectionData = await getProductCollectionWithSlug(collectionSlug);
  const newReleasesTotalAvailableCount = newReleases.totalAvailableCount;
  //const newReleasesProducts: ProductData[] = [(collectionDataObj as ProductData), ...newReleases.products.splice(0, 5)];
  const newReleasesProducts: ProductData[] = [...newReleases.products.splice(0, 6)];
  const creatorProfileImages: string[] = collectionDataObj?.productsInfoIncluded.reduce(
    (r: string[], e: ProductData) => (r.push(e.creatorProfileImageURL), r),
    [],
  );
  const collectionData: CollectionData = {
    ...collectionDataObj,
    creatorProfileImages: creatorProfileImages,
  };

  try {
    return {
      props: {
        homepageData: getHomepageData(),
        joinStudioData: getJoinStudioData(),
        footerData: getFooterData(),
        collectionData: collectionData,
        browseData: {
          newReleases: newReleasesProducts,
          newReleasesTotalAvailableCount: newReleasesTotalAvailableCount,
          popularProducts: popularProductsProducts,
        },
      },
    };
  } catch (err) {
    return {
      props: {
        isAuthenticated: false,
        homepageData: getHomepageData(),
        joinStudioData: getJoinStudioData(),
        footerData: getFooterData(),
        collectionData: collectionData,
        browseData: {
          newReleases: newReleasesProducts,
          newReleasesTotalAvailableCount: newReleasesTotalAvailableCount,
          popularProducts: popularProductsProducts,
        },
      },
    };
  }
};

export default Home;
