import { useEffect, useState } from "react";

export const useIsScrolledToActiveZone = (activeScrollY: number) => {
  const getIsScrolledToActiveZone = () => {
    if (typeof window !== "undefined") {
      return window.scrollY > activeScrollY;
    }
    return false;
  };

  const [isScrolledToActiveZone, setIsScrolledToActiveZone] = useState(getIsScrolledToActiveZone());

  useEffect(() => {
    const update = () => {
      setIsScrolledToActiveZone(getIsScrolledToActiveZone());
    };
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", update);
      update();
      return () => window.removeEventListener("scroll", update);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScrollY]);

  return isScrolledToActiveZone;
};
