import React from "react";

import Head from "next/head";

import { HomepageData } from "data/homepage";

const HomepageHead = ({ homepageData }: { homepageData: HomepageData }): React.ReactElement => {
  return (
    <Head>
      <title>{homepageData.meta.ogTitle}</title>
      <meta property="og:title" content={homepageData.meta.ogTitle} />
      <meta name="description" content={homepageData.meta.ogDescription} />
      <meta name="og:description" content={homepageData.meta.ogDescription} />
      <meta property="og:image" content={homepageData.meta.ogImg} />
      <link rel="canonical" href={homepageData.meta.canonicalUrl} />
    </Head>
  );
};

export default HomepageHead;
